import React, { Component, useState } from "react";
import { useTranslation } from "react-i18next";
import Draggable from 'react-draggable';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Dhaus from '../../../assets/images/whatsNew/dhaus.png';
import DhausAr from '../../../assets/images/whatsNew/d.haus-ar.png';
import Paul from '../../../assets/images/whatsNew/PAUL.png';
import Eyewa from '../../../assets/images/whatsNew/eyewa.png';
import FoundingDay from '../../../assets/images/whatsNew/founding-day.png';
import FlagDay from '../../../assets/images/whatsNew/flag-day.png';
import Mohalla from '../../../assets/images/whatsNew/Mohalla.png';
import Mizo from '../../../assets/images/whatsNew/Mizo.png';
import VOVDeal from '../../../assets/images/whatsNew/VOV-deals-ar.png';
import VOVDealEn from '../../../assets/images/whatsNew/VOVDeal.png';
import FanZone from '../../../assets/images/whatsNew/fan-zone.png';
import { BiArrowFromRight } from "react-icons/bi";
import { BsArrowBarRight } from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const WhatsNewSection = ({ businessArea }) => {
    const [t, i18n] = useTranslation("common");

    const [oldSlide, setOldSlide] = useState(1);
    const [activeSlide, setActiveSlide] = useState(1);
    const [activeSlide2, setActiveSlide2] = useState(1);

    return (
        <section className="flex flex-no-wrap items-start justify-between gap-6 px-4 mt-2 mb-10 overflow-x-scroll scrolling-touch md:px-14 scrollbar-hide">

            <Link data-aos="fade-in" data-aos-offset="200"
                data-aos-delay="100"
                data-aos-duration="700"
                data-aos-easing="ease-in-out" to={{
                    pathname: '/listing',
                    query: "dine",
                    card: i18n.language === "en" ? "Mizo" : "ميزو"
                }} className={`relative flex-none w-11/12 cursor-pointer md:w-4/6 rounded-2xl group ${businessArea ? "hidden" : "block"}`} >
                <img
                    alt="Store logo"
                    src={Mizo}
                    className='object-cover w-full my-4 fill-current h-96 rounded-2xl'
                />
                <div className={`absolute top-44 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8`}>
                    <h3 className={`capitalize font-light text-sm lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                        {t("whatsNewList.Mizo.type")}
                    </h3>
                    <h1 className="mt-4 mb-2 text-xl font-bold lg:text-5xl">
                        <strong>{t("whatsNewList.Mizo.title")}</strong>
                    </h1>
                    <p className="text-sm font-light lg:text-xl">
                        {t("whatsNewList.Mizo.body")}
                    </p>
                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                        <div href="" className="pt-1 text-base group-hover:underline ">
                            {t("viewMore")}
                        </div>
                        {i18n.language === 'en' ?

                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                            :
                            <IoIosArrowBack className="w-5 h-5" />
                        }
                    </div>
                </div>
            </Link>
            <Link data-aos="fade-in" data-aos-offset="200"
                data-aos-delay="100"
                data-aos-duration="700"
                data-aos-easing="ease-in-out" to={{
                    pathname: '/listing',
                    query: "shops",
                    card: i18n.language === "en" ? "D.Haus" : "د.هاوز"
                }} className={`relative flex-none w-11/12 cursor-pointer md:w-4/6 rounded-2xl group ${businessArea ? "hidden" : "block"}`}>
                <img
                    alt="Store logo"
                    src={i18n.language === "en" ? Dhaus : DhausAr}
                    className='object-cover w-full my-4 fill-current h-96 rounded-2xl'
                />
                <div className={`absolute top-44 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8`}>
                    <h3 className={`capitalize font-light text-sm lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                        {t("whatsNewList.Dhaus.type")}
                    </h3>
                    <h1 className="mt-4 mb-2 text-xl font-bold lg:text-5xl">
                        <strong>{t("whatsNewList.Dhaus.title")}</strong>
                    </h1>
                    <p className="text-sm font-light lg:text-xl">
                        {t("whatsNewList.Dhaus.body")}
                    </p>
                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                        <div href="" className="pt-1 text-base group-hover:underline ">
                            {t("viewMore")}
                        </div>
                        {i18n.language === 'en' ?

                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                            :
                            <IoIosArrowBack className="w-5 h-5" />
                        }
                    </div>
                </div>
            </Link>

        </section>
    );
}

export default WhatsNewSection;